import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import MainMenu from "../components/MainMenu/MainMenu";
import Footer from "../components/Footer/Footer";
import CopyRights from "../components/Footer/CopyRights/CopyRights";
import SEO from "../components/seo";
import "../assets/css/main.css";
import Header from "../components/Header/Header";
import HeadingCTA from "../components/HeadingCTA/HeadingCTA";
import ContactUs from "../components/ContactUs/ContactUs";
import Banner from "../images/casestudybg.png";
import { StaticImage } from "gatsby-plugin-image";
import Img from "gatsby-image";

const contact = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "contact-us/contact-us-header.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <MainMenu />
      <SEO
        seo={{
          metaTitle: "Contact Us - The Event Transportation Company",
          metaDescription:
            "Contact MOTUS | ONE - the event transportation company today. We are committed to ensuring the best level of service at the most economical rates.",
          shareImage: data.bannerImage.childImageSharp.fixed.src
        }}
      />
      <Header
        image={
          <StaticImage
            src="../assets/images/contact-us/contact-us-header.jpg"
            alt="world class event transportation"
          />
        }
        breadcrumbs={[{ label: "Contact us" }]}
        halfHeight
      >
        <HeadingCTA dark title={"Contact MOTUS | ONE"} h1 />
      </Header>
      <ContactUs />
      <Footer />
      <CopyRights />
    </Layout>
  );
};

export default contact;
